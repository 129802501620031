export default function () {

	$('.js-gender-binary-reinforcer:not(.js-gender-binary-reinforcer-done)').each(function () {
		var wrapper = $(this);

		var titleDropdown = wrapper.find('.js-title-options');
		var genderDropdown = wrapper.find('.js-gender-options');

		if (genderDropdown.val()) {
			genderDropdown.addClass('js-gender-fixed');
		}

		genderDropdown.change(function (e) {
			if (e.originalEvent) {
				genderDropdown.addClass('js-gender-fixed');
			}
		});

		titleDropdown.change(function () {
			var mostLikelyGender = titleDropdown.find(':selected').data('gender');

			if (mostLikelyGender && !genderDropdown.is('.js-gender-fixed')) {
				genderDropdown.val(mostLikelyGender);
			}
		});

		wrapper.addClass('js-gender-binary-reinforcer-done');
	});

}
